import { defineComponent as _defineComponent } from 'vue'
import { onMounted, computed, ref, watch } from "vue";

import { useRoute, useRouter } from "vue-router";
import { FACILITIES_MONITORING, SETTING_INFORMATION } from "@ems/constants";
import { IScopeTwoGridEmissionFactor } from "@/models/ScopeTwoEmissions";
import AreaMap from "@/components/AnyChart/AreaMap.vue";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";

import { formatLocalDate } from "@/utils/helpers/convertDateTime";
import FacilitiesMonitoring from "@ems/containers/FacilitiesMonitoring/FacilitiesMonitoring.module";
import S2RPModule from "@ems/containers/Dashboard/TheForm/S2RP.module";
import MetadataModule from "@/store/modules/Metadata";
import PPAModule from "@ems/containers/GreenOptions/TheForm/PPA.module";
import { groupDataByName } from "@/utils/helpers/buildDataFormat";
import { HASH, HASH_SCOPE2_INPUT_MANUAL } from "@ems/constants";
import FacilityModule from "@ems/containers/Tenant/TenantFacility.module";
import { IGetLocationFacilityDTO } from "@/models/Facility";
import GoogleMapPolyline from "@/components/GoogleMap/mapPolyline.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { TypeMap } from "@/constants";
import { TypeContentMarker } from "@/components/GoogleMap/ContentMarker";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

const activeFacility = ref("");
const dataFacilitiesMap = computed(
  () => MetadataModule.dataFacilitiesMapGetter
);

const filterCountry = (value: string, row: any) => {
  return row.CountryName === value;
};
const dataFacilitySummaryInfo = computed(() => {
  return FacilitiesMonitoring.dataFacilitySummaryInfoGetter;
});
const dataGreenHouseGases = computed(
  () => FacilitiesMonitoring.dataGreenHouseGasesGetter
);
const dataFacilityMonitoring = computed(
  () => FacilitiesMonitoring.facilityMonitoringOnlineDataGetter
);
const isLoadingFacilityMonitoringData = computed(
  () => FacilitiesMonitoring.loadingfacilityMonitoringDataGetter
);
const dataGetListLocationFacility = computed(
  () => FacilityModule.dataGetListLocationFacilityGetter
);
const dataGetListRecRetiredFacility = computed(
  () => FacilityModule.dataGetListRecRetiredFacilityGetter
);

const isLoadingListRecRetiredFacility = computed(
  () => FacilityModule.isLoadingListRecRetiredFacilityGetter
);
const dataFacilities = computed(() => MetadataModule.dataFacilitiesGetter);
const searching = ref("");
const filter = ref("");
const router = useRouter();
const route = useRoute();
const dataCenterMap = ref<any>({});
const handleClickDetailFacility = (idFacility: string) => {
  router.push(`${route.path}/${idFacility}`);
};
const handleEdit = (_: number, item: any) => {
  router.push(`${route.path}/${item.Value}`);
};
watch(
  () => dataFacilityMonitoring.value,
  async () => {
    if (dataFacilityMonitoring.value.length > 0) {
      dataCenterMap.value = dataFacilityMonitoring.value[0];
      FacilitiesMonitoring.fetchDataFacilitySummaryInfo(
        dataFacilityMonitoring.value[0].Value
      );
      FacilitiesMonitoring.getDataGreenHouseGasesAvoided(
        dataFacilityMonitoring.value[0].Value
      );
      await FacilityModule.getRecRetiredFacility({
        facilityId: dataFacilityMonitoring.value[0].Value,
      });
      activeFacility.value = dataFacilityMonitoring.value[0]?.Value;
    }
  }
);
onMounted(() => {
  FacilitiesMonitoring.getFacilityMonitoringAction();
});

const handleClickMarker = (value: any) => {
  activeFacility.value = value.Value;
  dataCenterMap.value = value;
  FacilityModule.getRecRetiredFacility({
    facilityId: value.Value,
  });
};

const __returned__ = { activeFacility, dataFacilitiesMap, filterCountry, dataFacilitySummaryInfo, dataGreenHouseGases, dataFacilityMonitoring, isLoadingFacilityMonitoringData, dataGetListLocationFacility, dataGetListRecRetiredFacility, isLoadingListRecRetiredFacility, dataFacilities, searching, filter, router, route, dataCenterMap, handleClickDetailFacility, handleEdit, handleClickMarker, onMounted, computed, ref, watch, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get FACILITIES_MONITORING() { return FACILITIES_MONITORING }, get SETTING_INFORMATION() { return SETTING_INFORMATION }, get IScopeTwoGridEmissionFactor() { return IScopeTwoGridEmissionFactor }, AreaMap, BaseSvgIcon, get formatLocalDate() { return formatLocalDate }, get FacilitiesMonitoring() { return FacilitiesMonitoring }, get S2RPModule() { return S2RPModule }, get MetadataModule() { return MetadataModule }, get PPAModule() { return PPAModule }, get groupDataByName() { return groupDataByName }, get HASH() { return HASH }, get HASH_SCOPE2_INPUT_MANUAL() { return HASH_SCOPE2_INPUT_MANUAL }, get FacilityModule() { return FacilityModule }, get IGetLocationFacilityDTO() { return IGetLocationFacilityDTO }, GoogleMapPolyline, BaseSpinner, get TypeMap() { return TypeMap }, get TypeContentMarker() { return TypeContentMarker } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})