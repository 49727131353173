import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "grid grid-cols-12 gap-4 mb-5 relative" }
const _hoisted_5 = { class: "col-span-12" }
const _hoisted_6 = { class: "box" }
const _hoisted_7 = { class: "col-span-12" }
const _hoisted_8 = { class: "box py-5" }
const _hoisted_9 = { class: "mb-3 flex justify-between items-center" }
const _hoisted_10 = { class: "flex items-center space-x-5" }
const _hoisted_11 = { class: "mx-5 text-xl font-semibold" }
const _hoisted_12 = { style: {"margin-right":"-10px","margin-left":"-10px"} }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "flex items-center space-x-5" }
const _hoisted_15 = { class: "flex items-center space-x-2" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "flex items-center space-x-2" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "flex items-center space-x-2" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "space-x-2" }
const _hoisted_22 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isLoadingListRecRetiredFacility)
      ? (_openBlock(), _createBlock($setup["BaseSpinner"], { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ol", _hoisted_2, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("li", null, ">", -1)),
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: "/facilities-monitoring" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Facilities Monitoring ")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode($setup["GoogleMapPolyline"], {
              dataCenterMap: $setup.dataCenterMap,
              data: $setup.dataGetListRecRetiredFacility,
              lineColor: '#8DC63E',
              classMap: _ctx.styles['map-size'],
              contentMarker: $setup.TypeContentMarker.CONTENT_ASSET_DETAIL
            }, null, 8, ["dataCenterMap", "data", "classMap", "contentMarker"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "text-left text-lg font-semibold" }, "Facility List", -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("p", null, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", null, "Total Facility: ", -1)),
                  _createElementVNode("span", _hoisted_11, _toDisplayString($setup.dataFacilityMonitoring.length), 1)
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn--green-primary h-12",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                  $setup.router.push(
                    `${$setup.route.path}/${$setup.FACILITIES_MONITORING.FACILITIES_CREATE.path}`
                  )
                ), ["prevent"]))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("label", { class: "mt-2" }, "Create New Facility", -1)
                ]))
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _withDirectives((_openBlock(), _createBlock(_component_el_table, {
                class: "w-full bg-white table-custom",
                style: {
                borderRadius: '4px',
                paddingLeft: '10px',
                paddingRight: '10px',
              },
                data: $setup.dataFacilityMonitoring,
                stripe: "",
                "max-height": "288",
                "cell-class-name": "text-black bg-white",
                "header-row-class-name": "tableHeaderRow-withBg",
                "row-class-name": "tableRow",
                "element-loading-text": "Loading...",
                "element-loading-background": "rgba(0, 0, 0, 0.6)"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    prop: "Name",
                    label: "FACILITY NAME"
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("span", {
                        onClick: ($event: any) => ($setup.handleClickMarker(scope.row)),
                        class: _normalizeClass(
                      $setup.activeFacility === scope.row.Value
                        ? 'text-black font-black underline hover:cursor-pointer'
                        : 'underline hover:cursor-pointer '
                    )
                      }, _toDisplayString(scope.row.Name), 11, _hoisted_13)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    filters: $setup.groupDataByName($setup.dataFacilityMonitoring as [], 'CountryName', 'CountryName'),
                    "filter-method": $setup.filterCountry,
                    prop: "CountryName",
                    label: "LOCATION"
                  }, null, 8, ["filters"]),
                  _createVNode(_component_el_table_column, {
                    prop: "Integration",
                    label: "INTEGRATION"
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(scope.row.Integration ? scope.row.Integration : "No"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "RenewablePerformance",
                    label: "RENEWABLE PERFORMANCE"
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(scope.row.RenewablePerformance?.toFixed(2)) + "%", 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "Name",
                    label: "ADD MANUAL RECORD",
                    width: "320"
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_router_link, {
                            class: "text-cafe-dark underline",
                            to: `/${$setup.SETTING_INFORMATION.path}/${$setup.SETTING_INFORMATION.S2RP_INPUT_MANUAL.path}#${$setup.HASH_SCOPE2_INPUT_MANUAL.ENERGY_CONSUMPTION}`
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", {
                                onClick: ($event: any) => (
                            $setup.S2RPModule.setEnergyLoadTotalFromFacility({
                              Country: scope.row.CountryId,
                              FacilityId: scope.row.Value,
                              Year:new Date().getFullYear().toString()
                            } as IScopeTwoGridEmissionFactor)
                          )
                              }, "Consumption", 8, _hoisted_16)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createVNode(_component_router_link, {
                            class: "text-cafe-dark underline",
                            to: `/${$setup.SETTING_INFORMATION.path}/${$setup.SETTING_INFORMATION.S2RP_INPUT_MANUAL.path}#${$setup.HASH_SCOPE2_INPUT_MANUAL.SOLAR_PV_GENERATION}`
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", {
                                onClick: ($event: any) => (
                             $setup.S2RPModule.setGeneratedTotalFromFacility({
                              Country: scope.row.CountryId,
                              FacilityId: scope.row.Value,
                              Year:new Date().getFullYear().toString()
                            } as IScopeTwoGridEmissionFactor)
                          )
                              }, "Onsite Solar", 8, _hoisted_18)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_component_router_link, {
                            class: "text-cafe-dark underline",
                            to: `/${$setup.SETTING_INFORMATION.path}/${$setup.SETTING_INFORMATION.S2RP_INPUT_MANUAL.path}#${$setup.HASH_SCOPE2_INPUT_MANUAL.RENEWABLES_ENERGY}`
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", {
                                onClick: ($event: any) => (
                            $setup.S2RPModule.setEnergyLoadTotalFromFacility({
                              Country: scope.row.CountryId,
                              FacilityId: scope.row.Value,
                              Year:new Date().getFullYear().toString()
                            }as IScopeTwoGridEmissionFactor)
                          )
                              }, "External REC", 8, _hoisted_20)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "UpdatedDate",
                    label: "LAST UPDATE"
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(scope.row.UpdatedDate
                      ? $setup.formatLocalDate(scope.row.UpdatedDate)
                      : "NA"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "Action",
                    width: "150"
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("button", {
                          type: "button",
                          onClick: _withModifiers(($event: any) => ($setup.handleEdit(scope.$index, scope.row)), ["prevent"])
                        }, [
                          _createVNode($setup["BaseSvgIcon"], {
                            class: "inline-block hover:text-blue-400 w-5 h-5",
                            name: "edit"
                          })
                        ], 8, _hoisted_22)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data"])), [
                [_directive_loading, $setup.isLoadingFacilityMonitoringData]
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}