import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { IGetRecDetailFacilityResponseDTO } from "@/models/Facility";
import { IRenewableOptionDetails } from "@/models/RenewableOptions";
import { onMounted, toRefs, watch } from "vue";
import { getContentMarker } from "./ContentMarker";

interface Props {
  data: any;
  dataCenterMap: any;
  lineColor: string;
  contentMarker: any;
  classMap: any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'mapPolyline',
  props: {
    data: {},
    dataCenterMap: {},
    lineColor: {},
    contentMarker: {},
    classMap: {}
  },
  setup(__props: any) {

const props = __props;

const { data, dataCenterMap, lineColor, classMap, contentMarker } =
  toRefs(props);

const initMap = () => {
  // if (process.env.NODE_ENV === "development") {
  //   return;
  // }
  // eslint-disable-next-line no-undef
  const googleMap = google;
  const dataMapping = data.value;
  if (dataMapping) {
    const markerImage = {
      url: require("@/assets/images/marker-google-map-white.png"), // url
      scaledSize: new googleMap.maps.Size(30, 40),
    };

    const map = new googleMap.maps.Map(document.getElementById("map"), {
      zoom: 5,
      center: new googleMap.maps.LatLng(
        dataCenterMap.value.Latitude,
        dataCenterMap.value.Longitude
      ),
      mapTypeId: googleMap.maps.MapTypeId.HYBRID,
    });

    const lineSymbol = {
      path: googleMap.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    };

    const endMarker = new googleMap.maps.Marker({
      map: map,
      position: {
        lat: dataCenterMap.value.Latitude,
        lng: dataCenterMap.value.Longitude,
      },
    });
    const contentString =
      '<div id="content">' +
      '<div id="siteNotice">' +
      "</div>" +
      '<div id="bodyContent">' +
      `<span class="font-bold"><b>${dataCenterMap.value?.Name}</b></span>` +
      `<p class="font-medium">${dataCenterMap.value?.CountryName} </p>` +
      `<p  class="font-bold leading-3 text-gray-secondary">Currently at </p>` +
      `<p class="font-semibold text-green-500">${dataCenterMap.value?.RenewablePerformance?.toFixed(
        2
      )}</p>` +
      `<p class="font-semibold text-gray-secondary">For year ${new Date().getFullYear()}</p>` +
      "</div>" +
      "</div>";
    const infoWindow = new googleMap.maps.InfoWindow({
      content: contentString,
      ariaLabel: "Uluru",
    });
    googleMap.maps.event.addListener(
      endMarker,
      "mouseover",
      (function (marker, time, infoWindow) {
        return function (evt: any) {
          infoWindow.setContent(contentString);
          infoWindow.open(map, marker);
        };
      })(endMarker, contentString, infoWindow)
    );
    googleMap.maps.event.addListener(
      endMarker,
      "mouseout",
      (function (marker, infoWindow) {
        return function (evt: any) {
          infoWindow.close(map, marker);
        };
      })(endMarker, infoWindow)
    );

    dataMapping?.map((item: IGetRecDetailFacilityResponseDTO) => {
      const startMarker = new googleMap.maps.Marker({
        map: map,
        position: { lat: item.Latitude, lng: item.Longitude },
        icon: markerImage,
      });
      googleMap.maps.event.addListener(
        startMarker,
        "mouseover",
        (function (marker, time, infoWindow) {
          return function (evt: any) {
            infoWindow.setContent(getContentMarker(contentMarker.value, item));
            infoWindow.open(map, marker);
          };
        })(startMarker, item.AssetName, infoWindow)
      );
      googleMap.maps.event.addListener(
        startMarker,
        "mouseout",
        (function (marker, infoWindow) {
          return function (evt: any) {
            infoWindow.close(map, marker);
          };
        })(startMarker, infoWindow)
      );
      const locationRecId = new googleMap.maps.Polyline({
        path: [
          { lat: item.Latitude, lng: item.Longitude },
          {
            lat: dataCenterMap.value.Latitude,
            lng: dataCenterMap.value.Longitude,
          },
        ],
        icons: [
          {
            icon: lineSymbol,
            repeat: "50px",
            offset: "100%",
          },
        ],
        geodesic: true,
        strokeColor: lineColor.value,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: map,
      });
      locationRecId.setMap(map);
    });
    googleMap.maps.event.addDomListener(window, "load", initMap);
  }
};
const initMapBlank = () => {
  // eslint-disable-next-line no-undef
  const googleMap = google;
  new googleMap.maps.Map(document.getElementById("map"), {
    zoom: 3,
    center: new googleMap.maps.LatLng(0, 0),
    mapTypeId: googleMap.maps.MapTypeId.HYBRID,
  });
};
onMounted(() => {
  initMapBlank();
});
watch(data, () => {
  initMap();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "map",
    class: _normalizeClass(_unref(classMap))
  }, null, 2))
}
}

})